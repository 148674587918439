// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//EDIT BASE TEMPLATE
//EDIT BASE TEMPLATE

//BTNs
.btn-primary {
  background-color: #2BBDC8;
  border-color: #2BBDC8;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #12A5AE;
  border-color: #ffffff;
}

.btn-success {
  background-color: #18a689;
  border-color: #18a689;
}
.btn-success:hover, .btn-success:active, .btn-success:focus {
  background-color: #117965;
  border-color: #ffffff;
}

.btn-danger {
  background-color: #E85468;
  border-color: #E85468;
}
.btn-danger:hover, .btn-danger:active, .btn-danger:focus {
  background-color: #a94442;
  border-color: #ffffff;
}

.btn-warning {
  background-color: #F4AE5D;
  border-color: #F4AE5D;
}
.btn-warning:hover, .btn-warning:active, .btn-warning:focus {
  background-color: #DE841B;
  border-color: #ffffff;
}

.btn-info {
  background-color: #D2DADE;
  border-color: #D2DADE;
  color: black;
}
.btn-info:hover, .btn-info:active, .btn-info:focus {
  background-color: #B7BEC1;
  border-color: #ffffff;
}
//END BTNs

//TEXTS
.text-success {
  color: #18a689;
}

.text-primary {
  color: #2BBDC8;
}
//END TEXTS

//LABELS
.label-info {
  background-color: #D2DADE;
  border-color: #D2DADE;
  color: black;
}
//END LABELS

//MENU
ul.nav-second-level {
  a > span {
    display: inline;
  }
}
//END MENU

//PAGINATION
ul.pagination {
  margin: 10px 0 0 15px;
  li.active {
    span, span:hover {
      background-color: #238DC7; // 238DC7 // 2BBDC8
    }
  }
}
//END PAGINATION

//LOGIN
h1.logo-name {
  margin: 0px;
  a {
    img {
      height: 150px;
    }
  }
}

//END EDIT BASE TEMPLATE
//END EDIT BASE TEMPLATE

a.social_login_btn > i {
  float: left;
  margin-top: 4px;
}

a.social_login_btn.fb {
  background-color: #3b5998;
  border: solid 1px #3b5998;
}

a.social_login_btn.gp {
  background-color: #d34836;
  border: solid 1px #d34836;
}

a.social_login_btn:hover {
  border: solid 1px #ffffff;
}

// navigation bar
.navbar-left-title {
  padding: 0px;
  margin: 14px 0px 0px 7px;
  font-size: 16px;
  float: left;
  h2 {
    margin: 3px;
  }
}

.border-super-user {
  border-left: #a94442 solid 7px;
}

.ibox-title.border-danger {
  border-top: #a94442 solid 3px;
}

.ibox-heading.border-danger {
  border-top: #a94442 solid 1px;
}

tr.highlight {
  color: whitesmoke;
  td {
    border-top: solid 3px #a94442 !important;
    border-bottom: solid 3px #a94442 !important;
    background: #18a689;
  }
}

img.profile_center {
  margin: auto;
}

div.notifications {
  .list-group-item {
    a {
      color: #676A6C;
    }
  }

  .list-group-item:hover {
    background: #F3F3F4;
    a {color: #18a689;}
  }
}

div.chat-discussion {
  background: transparent;
}

//login
.loginscreen {
  a.btn.btn-white:hover {
    border: #18a689 solid 1px;
    color: #000000;
  }
}

.i-check-error > div {
  background-color: #a94442;
}

div.required > label:after {
  color: #a94442;
  content:" *";
}

.modal-backdrop, .modal-backdrop.in{
  display: none;
}

//profile
img.profile-picture {
  width: 48px;
  height: 48px;
}

button.profile-description {
  position: absolute;
  top: 15px;
  right: 30px;
}

//home
a.home_group_link {
  color: lightgrey;
  padding: 2px 2px 4px 2px;
}
a.home_group_link:hover {
  color: #18a689;
}


//summernote
.note-editor {
  min-height: 50px;
  border: 0px !important;
}

div.with-margin {
  margin: 20px;
}

.large-summernote .note-editing-area {
  min-height: 300px;
}

div.no-margins > .note-editor {
  margin-bottom: 0px;
}

//progress bar
.html5-progress-bar {
  background-color: #fff;
}
.html5-progress-bar progress {
  background-color: #cdcdcd;
  border: 0;
  width: 80%;
  height: 10px;
  border-radius: 4px;
  display: none;
}
.html5-progress-bar progress[value] {
  display: block;
}
.html5-progress-bar progress::-webkit-progress-bar {
  background-color: #cdcdcd;
  border-radius: 9px;
}
.html5-progress-bar progress::-webkit-progress-value {
  background: #cdeb8e;
  background: -moz-linear-gradient(top,  #cdeb8e 0%, #a5c956 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cdeb8e), color-stop(100%,#a5c956));
  background: -webkit-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
  background: -o-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
  background: -ms-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
  background: linear-gradient(to bottom,  #cdeb8e 0%,#a5c956 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeb8e', endColorstr='#a5c956',GradientType=0 );
  border-radius: 9px;
}
.html5-progress-bar progress::-moz-progress-bar {
  background: #cdeb8e;
  background: -moz-linear-gradient(top,  #cdeb8e 0%, #a5c956 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cdeb8e), color-stop(100%,#a5c956));
  background: -webkit-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
  background: -o-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
  background: -ms-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
  background: linear-gradient(to bottom,  #cdeb8e 0%,#a5c956 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeb8e', endColorstr='#a5c956',GradientType=0 );
  border-radius: 9px;
}
.html5-progress-bar .progress-value {
  display: none;
  position: relative;
  line-height: 10px;
  margin-left: 5px;
  font-size: .8em;
  color: #555;
  height: 10px;
  float: right;
  top: 0px;
  right: 0px;
}

.html5-progress-bar .progress-value:after {
  content:" %";
}

form.deleter-form {
  float: left;
  button {
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

form.deleter-form.simple-trash {
  position: absolute;
  top: 6px;
  right: 8px;
  float: none;
  button {
    background: transparent;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: none;
  }
}

form.deleter-form.simple-trash-righter {
  position: absolute;
  top: -1px;
  right: -2px;
  float: none;
  button {
    background: transparent;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: none;
  }
}

div.chat-discussion {
  height: auto;
  background-color: #ffffff;
}

//SEARCH
/* SEARCH */

.search-bar {
  position: absolute;
}

@-moz-document url-prefix() {
  .search-bar {
    left: 150px;
  }
}

.search-bar .cancel {
  position: absolute;
  top: 20px;
  right: 5px;
  z-index: 1;
  color: #4f5b66;
}

.search-bar .cancel:hover {
  cursor: pointer;
}

.search-bar input#top-search {
  border: none;
  font-size: 10pt;
  float: left;
  padding-left: 35px;
  padding-right: 20px;
}

.search-bar input#top-search::-webkit-input-placeholder {
  color: #65737e;
}

.search-bar input#top-search:-moz-placeholder { /* Firefox 18- */
  color: #65737e;
}

.search-bar input#top-search::-moz-placeholder {  /* Firefox 19+ */
  color: #65737e;
}

.search-bar input#top-search:-ms-input-placeholder {
  color: #65737e;
}

.search-bar input#top-search:focus, .search-bar input#top-search:hover, .search-bar input#top-search:active{
  outline: none;
}

//CALENDAR
div.fc-view {
  td.fc-week-number, td.fc-axis.fc-time {
    background-color: #E5E5E5;
  }
  td.fc-day.fc-sat, td.fc-day.fc-sun {
    background-color: #F1F1F1;
  }
  td.fc-day.fc-today {
    background-color: #A8E0D3;
  }
}

a.event-create {
  padding: 3px 6px 3px 6px;
  margin-left: 10px;
}

div#calendar-loading {
  margin-top: 15px;
  margin-left: 30px;
}

h5.cal-title {
  padding-left: 20px;
}

//calendar EVENT classes
.ev_0 {
  background-color: #30B493;
  border-color: #30B493;
  color: black;
} //Holiday

.ev_1 {
  background-color: grey;
  border-color: grey;
  color: white;
} //Private

.ev_2 {
  background-color: #3CC6C7;
  border-color: #3CC6C7;
  color: black;
} //Suggested Training
.ev_3 {
  background-color: #3CC6C7;
  border-color: #3CC6C7;
  color: black;
  border-right: solid black 3px;
} //Training

.ev_4 {
  background-color: #FFD900;
  border-color: #FFD900;
  color: black;
} //Other

.ev_5 {
  background-color: #E85468;
  border-color: #E85468;
  color: black;
} //Regional Comp
.ev_6 {
  background-color: #E85468;
  border-color: #E85468;
  color: black;
  border-right: solid black 3px;
} //National Comp
.ev_7 {
  background-color: #E85468;
  border-color: #E85468;
  color: black;
  border-left: solid black 3px;
  border-right: solid black 3px;
} //International Comp

.ev_8 {
  background-color: #EF8BFF;
  border-color: #EF8BFF;
  color: black;
} //Route setting

.ev_9 {
  background-color: #F4AE5D;
  border-color: #F4AE5D;
  color: black;
} //Meeting

//Google MAP
div#g_map {
  .controls {
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 300px;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  .pac-container {
    font-family: Roboto;
  }

  #type-selector {
    color: #fff;
    background-color: #4d90fe;
    padding: 5px 11px 0px 11px;
  }

  #type-selector label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }
}

//DataTables
a.dt-button {
  background-color: #2BBDC8;
  background-image: none;
  color: whitesmoke;
  font-weight: bold;
  border-color: #2BBDC8;
}
a.dt-button:hover:not(.disabled), a.dt-button:active:not(.disabled), a.dt-button:focus:not(.disabled) {
  background-color: #12A5AE;
  background-image: none;
  border-color: #ffffff;
}

//amazing nice diff
div.diff_color {
  position: relative;
  padding: 0px;
  height: 24px;
  left: 5px;
  right: 5px;
  border: solid 1px black;

  div.diff_c_main {
    height: 17px;
    width: 100%;
    padding: 0px;
  }

  div.diff_c_list {
    height: 7px;
    width: 100%;
    padding: 0px;
    div {
      float: left;
      height: 5px;
      padding: 0px;
    }
  }
}

.print_color {
  display: none;
}

span.irs.slider_off {
  .irs-bar {
    border-top: 1px solid #E85468;
    border-bottom: 1px solid #E85468;
    background: #E85468;
  }
  .irs-bar-edge {
    border: 1px solid #E85468;
    background: #E85468;
  }
  .irs-from, .irs-to, .irs-single {
    background: #E85468;
  }
  .irs-grid-pol {
    background: #E85468;
  }
}

//BORDERS
.gray-borders {
  border: solid grey 1px;
}

//RADIO BTN GROUP
div.btn-group-radio {

  position: relative;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;

  div.own-radio {
    label {
      padding: 6px 12px 6px 12px;
    }
    label.not-first {
      border-left: none;
    }

    input {
      display: none;
    }
    input[type="radio"]:checked+label{
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
      z-index: 2;
      border: 1px solid #d2d2d2;
      font-weight: bold;
      background: #DCFFFF;
    }
    input[type="radio"]+label{
      border: 1px solid #d2d2d2;
    }
  }

}

div.form-group.has-error {
  div.btn-group-radio {
    div.own-radio > label {
      border: solid 1px #E85468;
    }
  }
}

//feedback
div#global_feedback {
  position: fixed;
  left: 10px;
  bottom: 10px;
  a {
    font-size: 0.8em;
  }
}

body.unlogged {
  background-color: #238DC7;
}

//cath results view
table.results-detail {
  padding: 0;
  margin: 0;
  font-size: 0.9em;
  color: black;
  td {
    border: solid 1px grey;
    margin: 0;
    width: 20px;
    text-align: center;
  }
  td.ok {
    background-color: #18a689;
    color: whitesmoke;
  }
}
table.results-table {
  margin-left:auto;
  margin-right:auto;
  tr:nth-child(even) {
    background-color: #EEEEEE;
  }
  td.padding {
    padding-left: 15px;
    padding-right: 15px;
  }
  td.rank {
    font-size: 1.2em;
    text-align: center;
  }
  tr.cath_name {
    td {
      color: whitesmoke;
      background: #313F50;
    }
    small {
      color: whitesmoke;
    }
  }
  tr.border-top > td {
    border-top: solid 2px #E85468;
  }
}
div.print_cat {
  color: #000000;
  h4 {
    margin: 0px;
  }
  padding: 0px 20px 0px 20px;
  table.results-detail {
    font-size: 0.7em !important;
    td {
      width: 16px;
    }
  }
  td.notes {
    border-bottom: solid 1px black;
    height: 80px;
    small {
      text-decoration: underline;
    }
  }
  td.result {
    margin: 5px;
    border: solid black 2px;
  }
  @media print {
    font-size: 0.8em !important;
    table.page_break { page-break-before: always; }
  }
}
table.results-table.adapt {
  thead {
    font-size: 1vw;
  }
  font-size: 1.5vw;
  table.results-detail {
    font-size: 0.7em !important;
    td {
      width: 30px;
    }
  }
  td.user-name {
    font-size: 1vw;
    small {
      font-size: 0.6vw;
    }
  }
}
td.final_input {
  input {
    font-size: 1.8em;
  }
}